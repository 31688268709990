import { PLATFORM } from 'aurelia-pal';
import { Redirect } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from './common/security';
import { c } from './common/common';
import { UiWidgets } from 'services/ui-widgets';
import { I18n } from './common/i18n';
import { Bootstrapper } from 'common/bootstrapper';
import { Versions } from 'services/versions';
import { Config } from 'services/config';
import { Sites } from 'services/sites';
import { hideSplashScreen, addAppStateChangeListener, initializeKeyboardEvents, screenOrientation, addScreenOrientationListener } from 'services/capacitor';
import environment from '../config/environment.json';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

export class App {
    static inject = [EventAggregator, Security, UiWidgets, I18n, Bootstrapper, Versions, Config, Sites];

    language = null;
    languageOptions = [];
    env = environment.env;

    _handlers = [];

    pageTitle = 'Dashboard';
    pageDescription = 'This is the text under the title';

    _sidebarDrawer;

    constructor(ea, security, uiWidgets, i18n, bootstrapper, versions, config, sites) {
        this._ea = ea;
	    this.security = security;
	    this.uiWidgets = uiWidgets;
	    this.i18n = i18n;
        this._bootstrapper = bootstrapper;
        this._versions = versions;
        this._config = config;
        this._sites = sites;

        this._initializeMobileApp();
    }

    _initializeMobileApp() {
        try {
            addAppStateChangeListener(async(state) => await this._onAppStateChanged(state));
            addScreenOrientationListener((orientation) => this._applyScreenOrientation(orientation));
            CapacitorUpdater.notifyAppReady();
            CapacitorUpdater.setChannel({ channel: environment.capgoChannel });
        } catch (err) {
            console.log(err);
        }
    }

    async _onAppStateChanged(state) {
        if (state && state.isActive) {
            // console.log('app.device-resume');
        } else {
            // console.log('app.device-pause');
        }
    }

    async _applyScreenOrientation(orientation) {
        try {
            if (!orientation) orientation = await screenOrientation();
            document.body.classList.remove('lpfn-orientation-landscape');
            document.body.classList.remove('lpfn-orientation-portrait');
            document.body.classList.add(`lpfn-orientation-${orientation}`);
        } catch (err) {
            console.log(err);
        }
    }

    configureRouter(config, router){
        config.title = this._sites.title();
		config.addPipelineStep('authorize', AuthXStep);
		config.addPipelineStep('authorize', AuthZStep);
		config.addPipelineStep('authorize', AuthOnboardingStep);
		config.addPreRenderStep(onPreRender);
		config.addPostRenderStep(onPostRender);
        config.addPostRenderStep(new CheckClientVersionStep(this._ea, this._versions));
		config.map([
            {
                route: [
                    '',
                    'login',
                    'sign-up',
                    'sign-up/:email',
                    'reset-password',
                    'reset-password-confirm/:token',
                    'logout',
                    'privacy-policy',
                    'terms-of-use',
                    'writing-agent-agreement'
                ],
                moduleId: PLATFORM.moduleName('account/index'),
                settings: {},
            },
            {
                route: 'writing-agent-agreement',
                moduleId: PLATFORM.moduleName('writing-agent-agreement'),
                settings: {},
            },
            {
                route: 'members',
                moduleId: PLATFORM.moduleName('members/_index'),
                settings: {},
            },
            {
                route: 'admin',
                moduleId: PLATFORM.moduleName('admin/_index'),
                settings: {},
            },
	    ]);

        config.mapUnknownRoutes(this.handleUnknownRoutes);

		this.router = router;
	}

    handleUnknownRoutes = (instruction) => {
        return { route: '', moduleId: 'login' };
    }

    async activate() {
        try {
            await this._bootstrapper.forApp();
            this.languageOptions.push({locale:'en', name: 'English'});
            this.language = this.languageOptions[0];
            this.languageOptions = this.i18n.supportedLanguages;
            this.language = this.i18n.language;

            if (!this.security.isAuthenticated) return;
            await this._bootstrapper.forLogin();
        } catch (err) {
            console.log(err);
        }
	}

    async attached() {
        try {
            this._handlers.push(this._ea.subscribe(c.EventKeys.site.openLightbox, (data) => this._openLightbox(data)));
            this._handlers.push(this._ea.subscribe(c.EventKeys.loginAuthenticationUpdated, () => this._addScripts()));
            this._handlers.push(this._ea.subscribe(c.EventKeys.site.openRightPanel, (data) => this._openRightPanel(data)));

            this._addScripts();
            await this._applyTheme();
            initializeKeyboardEvents(this._ea);
            this._applyScreenOrientation();
            if (!this.security.isAuthenticated) return;
            this._bootstrapper.forLoginBackground();
        } catch (err) {
            console.log('app attached error', err);
        } finally {
            hideSplashScreen();
        }
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _addScripts() {
        try {
            const chatWidgetContainer = document.getElementById('chat-widget-container');
            if (this.security.isAuthenticated) {
                const existingBodyScript = document.getElementById('lpfn-body-script');
                if (!existingBodyScript) {
                    const bodyJs = this._config.value('bodyScript');
                    if (bodyJs) {
                        const bodyScriptEl = document.createElement('script');
                        bodyScriptEl.setAttribute('id', 'lpfn-body-script');
                        bodyScriptEl.text = c.Helpers.replaceVariables(this.security, bodyJs);
                        document.body.appendChild(bodyScriptEl);
                    }
                } else if (chatWidgetContainer) {
                    chatWidgetContainer.style.display = 'block';
                }
            } else {
                if (chatWidgetContainer) {
                    chatWidgetContainer.style.display = 'none';
                }
            }
            if (this.security.isAuthenticated) {
                const existingHeadScript = document.getElementById('lpfn-head-script');
                if (!existingHeadScript) {
                    const script = this._config.value('headScript');
                    if (script) {
                        const headScript = document.createElement('script');
                        headScript.setAttribute('id', 'lpfn-head-script');
                        headScript.text = script;
                        document.head.appendChild(headScript);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async _applyTheme() {
        try {
            const theme = await this._sites.theme();
            var styleEl = document.createElement('style');
            styleEl.type = 'text/css';
            if (styleEl.styleSheet) styleEl.styleSheet.cssText = theme.css;
            else styleEl.appendChild(document.createTextNode(theme.css));
            document.getElementsByTagName("head")[0].appendChild(styleEl);
        } catch (err) {
            console.log(err);
        }
    }

	async changeLanguage(lang) {
        try {
    	    this.language = lang;
	        await this.i18n.setLocale(lang.locale);
            await this.uiWidgets.initialize();
            this._ea.publish(c.EventKeys.reloadUiWidget, null);
        } catch (err) {
            console.log(err);
        }
    }

    _openLightbox(data) {
        if (data.jotform) {
            const existing = document.getElementById('lpfn-jotform-script');
            if (existing) existing.remove();
            const qsParams = data.jotform.variablesQS.split('&');
            const iframeParameters = {};
            qsParams.forEach(qsParam => {
                const param = qsParam.split('=');
                iframeParameters[param[0]] = param[1];
            });
            const js = `var JFL_${data.jotform.id} = new JotformFeedback({formId:'${data.jotform.id}',base:'https://form.jotform.com/',windowTitle:'${data.jotform.windowTitle}',background:'#FFA500',fontColor:'#FFFFFF',type:'false',height:500,width:700,openOnLoad:true,iframeParameters:${JSON.stringify(iframeParameters)}})`;
            const bodyScript = document.createElement('script');
            bodyScript.setAttribute('id', 'lpfn-jotform-script');
            bodyScript.text = js;
            document.body.appendChild(bodyScript);
            return;
        }
        this.showLightbox = true;
        if (data.embedSrc) {
            this.showEmbedLightbox = true;
            this.embedSrc = data.embedSrc;
        }
        this.embedSrcIsVideo = data.isVideo;
        this.lightboxVM.open(data.isVideo, data.fullHeight, data.zIndex);
    }

    lightboxClosed() {
        this.showLightbox = false;
        this.showEmbedLightbox = false;
        this.embedSrc = 'about:blank';
    }
}

class onPostRender {
    static inject = [EventAggregator];
    _ea;

    constructor(ea) {
        this._ea = ea;
    }

    run(navigationInstruction, next) {
        this._ea.publish(c.EventKeys.navigation.changed, { fragment: navigationInstruction.fragment });
        window.scrollTo(0, 0);
        return next();
    }
}

class onPreRender {
    static inject = [EventAggregator];
    _ea;

    constructor(ea) {
        this._ea = ea;
    }

    run(navigationInstruction, next) {
        this._ea.publish(c.EventKeys.navigation.changing, { fragment: navigationInstruction.fragment });
        window.scrollTo(0, 0);
        return next();
    }
}

class AuthXStep {
    static inject = [Security];

    constructor(security) {
		this.security = security;
	}
	
	run(navigationInstruction, next) {
		if (navigationInstruction.getAllInstructions().some(i => i.config.membersOnly)) {
			if (!this.security.isAuthenticated) {
				this.security.setOrigin(navigationInstruction.fragment);
				return next.cancel(new Redirect('login'));
			}
		}
		return next();
	}
}

class AuthZStep {
    static inject = [Security];

	constructor(security) {
		this._security = security;
	}
	
	run(navigationInstruction, next) {
        if (navigationInstruction.getAllInstructions().some(i => i.config.settings.roles)) {
            const allInstructions = navigationInstruction.getAllInstructions();
            for (let i = 0; i < allInstructions.length; i++) {
                if (!allInstructions[i].config || !allInstructions[i].config.settings || !allInstructions[i].config.settings.roles) continue;
                if (this._security.isInRole(allInstructions[i].config.settings.roles)) continue;
                return next.cancel(new Redirect('#/'));
            }
        }
		if (navigationInstruction.getAllInstructions().some(i => i.config.adminOnly)) {
			if (!this._security.isAdmin) {
                console.log('isAdmin, not admin');
				return next.cancel(new Redirect('#/'));
			}
		}
		return next();
	}
}

class AuthOnboardingStep {
    static inject = [Security];

	constructor(security) {
		this._security = security;
	}
	
	run(navigationInstruction, next) {
        if (!this._security.isAuthenticated) return next();
        if (!this._security.agent.onboardingComplete && (navigationInstruction.fragment === '/members')) {
            return next.cancel(new Redirect('#/members/onboarding'));
        }
		return next();
	}
}

class CheckClientVersionStep {
    _ea;
    _version;

    constructor(ea, version) {
        this._ea = ea;
        this._version = version;
    }

    async run(navigationInstruction, next) {
        try {
            // For now, only doing the new client version check for admin
            if (await this._version.hasNewClientVersion()) {
                this._ea.publish(c.EventKeys.newClientVersion);
            }
        } catch (err) {
            console.log('CheckClientVersionStep', err);
        }
        return next();
    }
}
